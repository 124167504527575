// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
import { Notyf } from 'notyf';
import flatpickr from "flatpickr";
import 'tom-select/dist/css/tom-select.css';
import '../controllers'
import './additional_styles/flatpickr.css';
import './additional_styles/tomselect.css';
import 'notyf/notyf.min.css';
import '@hotwired/stimulus';
import '@hotwired/turbo-rails';
import '@tabler/core/dist/css/tabler.min.css';
import '@tabler/core/dist/js/tabler.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

window.showNotyfAlert = (message, type = 'success') => {
  let notyf = new Notyf({
    duration: 5000,
    position: { x: 'center', y: 'top' },
    dismissible: true
  });
  
  if (type === 'error') {
    notyf.error(message);
  } else {
    notyf.success(message);
  }
};
