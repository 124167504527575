import { Controller } from "@hotwired/stimulus";
import TomSelect from "https://cdn.skypack.dev/tom-select@2.3.1";

export default class extends Controller {
  static values = { options: Array, optionCreate: { type: String, default: "Add" }, noResults: { type: String, default: "Sem resultados" } }

  connect() {
    const options = this.optionsValue.map(option => ({ value: option, text: option }));

    this.tomSelect = new TomSelect(this.element, {
      options: options,
      persist: false,
      createOnBlur: false,
      create: false,
      render: this.#render,
    });
  }

  disconnect() {
    if (this.tomSelect) {
      this.tomSelect.destroy();
    }
  }

  get #render() {
    return {
      option_create: (data, escape) => {
        return `<div class="create">${this.optionCreateValue} <b>${escape(data.input)}</b>...</div>`;
      },
      no_results: () => {
        return `<div class="no-results">${this.noResultsValue}</div>`;
      },
    };
  }
}