// import { Application } from "stimulus"
import { Application } from "@hotwired/stimulus"
import "@hotwired/turbo-rails"
import { registerControllers } from "stimulus-vite-helpers"
// import { Autocomplete } from 'stimulus-autocomplete'

// Start the Stimulus application.
const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug    = false
window.Stimulus      = application

export { application }

// Controller files must be named *_controller.js.
const controllers = import.meta.glob("./**/*_controller.js", { eager: true })
registerControllers(application, controllers)

// application.register('autocomplete', Autocomplete)
