import TomSelect from "tom-select";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { orderedData: Array }

  connect() {
    //#skipcq: JS-R1002
    const select = this.element.options;
    const options = {};
    for (let i = 0; i < select.length; i++) {
      const option = select[i];
      options[option.text] = option.value;
    }

    //#skipcq: JS-R1002
    if (this.hasOrderedDataValue) {
      new TomSelect(this.element, {
        create: function (input) {
          if(input in options) {
            return { value: options[input], text: input };
          } else {
            return false;
          }
        },
        createOnBlur: true,
        persist: false,
        plugins: ["remove_button"],
        maxItems: parseInt(this.element.getAttribute("tom_select_max_items")) ?? 30,
        items: this.orderedDataValue
      })
    } else {
      new TomSelect(this.element, {
        create: function (input) {
          if(input in options) {
            return { value: options[input], text: input };
          } else {
            return false;
          }
        },
        createOnBlur: true,
        persist: false,
        plugins: ["remove_button"],
        maxItems: parseInt(this.element.getAttribute("tom_select_max_items")) ?? 30,
      })
    }
  }
}
